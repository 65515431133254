import { MessageTypes } from '../types/messageTypes';
import MessageHelper from './messageHelper';

class ErrorHelper {
    static getCustomWebsiteError(error: any): Error {
        if (error && error.response) {
            return new Error(error.status + '|' + error.response.statusText);
        } else {
            return new Error(error.status);
        }
    }

    static getCustomWebsiteErrorFunction() {
        return (error: any) => {
            throw this.GetErrorObject(error);
        };
    }

    static showMessageAndRethrow() {
        return (error: any) => {
            MessageHelper.showMessage({type: MessageTypes.ERROR, message: this.GetErrorObject(error).message});
            throw this.GetErrorObject(error).message;
        };
    }

    private static GetErrorObject(error: any) : Error
    {debugger;
        if (error && error.response) {
            if(!error.response.statusText) {
                if(error.response.noContent) throw new Error(error.status + '|' + 'No content');
                if(error.response.badRequest) throw new Error(error.status + '|' + 'Bad request');
                if(error.response.notFound) throw new Error(error.status + '|' + 'Not found');
            }
            return new Error(error.status + '|' + error.response.statusText);
        } else if (error) {
            if(error.status){
                return new Error(error.status);
            }
            return new Error(error.message);
        }
        return new Error('Unknown error occurred.');
    }
}

export default ErrorHelper;
